
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { parseTime } from "@/utils";
import DTCls from "@/utils/datetime";
import Pagination from "@/comp/Pagination/index.vue";
import { pkRaceList, pkRaceSave, pkRaceDetail, pkRaceRemove, pkRaceUserList } from "@/api/request/activity";

//组件
@Component({
  name: "PKRace",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //请求参数
  private listQuery: any = {
    //页码数据
    page: 1,
    page_size: 20,

    //临时数据
    id_str: "",

    //请求参数
    id: 0,
    name: "",
  };

  //创建
  created() {
    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.id = Number(this.listQuery.id_str);

    //获取数据
    const { data } = await pkRaceList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //处理创建
  private handleCreate(): void {
    //数据赋值
    this.form = {
      //临时数据
      time: [],

      //请求数据
      id: 0,
      name: "",
      end_time: 0,
      begin_time: 0,
      bloggers: [],
    };
    this.isCreate = true;

    //显示界面
    this.dialogVisible = true;
  }

  //处理编辑
  private async handleEdit(row: any) {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await pkRaceDetail({ id: row.id });

    //数据赋值
    this.form = {
      //临时数据
      time: [parseTime(row.begin_time, "{y}-{m}-{d} {h}"), parseTime(row.end_time, "{y}-{m}-{d} {h}")],

      //请求数据
      id: row.id,
      name: row.name,
      end_time: row.end_time,
      begin_time: row.begin_time,
      bloggers: [],
    };
    this.isCreate = false;
    for (var i: number = 0; i < data.bloggers.length; i++) {
      this.form.bloggers.push({ daily_id: data.bloggers[i].daily_id, blogger_id: data.bloggers[i].blogger_id });
    }

    //显示界面
    this.dialogVisible = true;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理详情
  private async handleDetail(id: number) {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await pkRaceDetail({ id: id });

    //显示对话框
    this.detail = data;
    this.detailDialogVisible = true;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理删除
  private handleDelete(id: number): void {
    //显示提示
    this.$confirm(`您确定要删除该PK赛？`, "警告", {
      confirmButtonText: this.$t("permission.confirm") as string,
      cancelButtonText: this.$t("permission.cancel") as string,
      type: "warning",
    })
      .then(async () => {
        //删除PK赛
        const res = await pkRaceRemove({ id: id });

        //重新获取列表
        this.getList();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //处理用户列表
  private handleUserList(id: number): void {
    //数据赋值
    this.userListQuery = {
      //页码数据
      page: 1,
      page_size: 20,

      //临时数据
      user_id_str: "",
      blogger_id_str: "",

      //请求参数
      race_id: id,
      user_id: 0,
      blogger_id: 0,
    };

    //显示界面
    this.userListDialogVisible = true;

    //获取用户列表
    this.getUserList();
  }

  //------------------------------ 用户列表 ------------------------------
  //定义变量
  private userList: any[] = [];
  private userTotal: number = 0;
  private userListDialogVisible: boolean = false;
  private userListDialogLoading: boolean = false;

  //请求参数
  private userListQuery: any = {
    //页码数据
    page: 1,
    page_size: 20,

    //临时数据
    user_id_str: "",
    blogger_id_str: "",

    //请求参数
    race_id: 0,
    user_id: 0,
    blogger_id: 0,
  };

  //获取用户列表
  private async getUserList() {
    //显示等待
    this.userListDialogLoading = true;

    //数据赋值
    this.userListQuery.user_id = Number(this.userListQuery.user_id_str);
    this.userListQuery.blogger_id = Number(this.userListQuery.blogger_id_str);

    //获取数据
    const { data } = await pkRaceUserList(this.userListQuery);

    //数据赋值
    this.userList = data.list;
    this.userTotal = data.total;

    //隐藏等待
    setTimeout(() => {
      this.userListDialogLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleUserListFilter(): void {
    //数据赋值
    this.userListQuery.page = 1;

    //获取数据
    this.getUserList();
  }

  //------------------------------ 添加/编辑活动 -----------------------------
  //定义变量
  private isCreate: boolean = false; //是否创建
  private dialogVisible: boolean = false; //显示随画框
  private dialogLoading: boolean = false; //对话框等待

  //表单数据
  private form: any = {
    //临时数据
    time: [],

    //请求数据
    id: 0,
    name: "",
    end_time: 0,
    begin_time: 0,
    bloggers: [],
  };

  //添加博主
  private addBlogger(): void {
    //数据赋值
    this.form.bloggers.push({ daily_id: "", blogger_id: "" });
  }

  //处理创建
  private async createHandle() {
    //数据赋值
    var bloggers: any[] = [];
    for (var i: number = 0; i < this.form.bloggers.length; i++) {
      if (this.form.bloggers[i].daily_id && Number(this.form.bloggers[i].daily_id) != 0 && this.form.bloggers[i].blogger_id && Number(this.form.bloggers[i].blogger_id) != 0) {
        bloggers.push({
          daily_id: Number(this.form.bloggers[i].daily_id),
          blogger_id: Number(this.form.bloggers[i].blogger_id),
        });
      }
    }

    //判断返回
    if (this.form.name == "") return this.$message.error("请输入名称");
    if (bloggers.length != 2) return this.$message.error("博主人数 == 2");
    if (!this.form.time[0] || this.form.time[0] == 0) return this.$message.error("清选择开始时间");
    if (!this.form.time[1] || this.form.time[1] == 0) return this.$message.error("清选择结束时间");

    //数据赋值
    this.form.bloggers = bloggers;
    this.form.begin_time = new Date(this.form.time[0] + ":00:00").getTime() / 1000;
    this.form.end_time = new Date(this.form.time[1] + ":00:00").getTime() / 1000;

    //显示等待
    this.dialogLoading = true;

    //添加活动
    await pkRaceSave(this.form);

    //显示提示
    this.$notify({ title: "成功", message: "添加成功", type: "success", duration: 2000 });

    //隐藏界面
    this.dialogLoading = false;
    this.dialogVisible = false;

    //重新获取数据
    this.getList();
  }

  //处理编辑
  private async updataHandle() {
    //数据赋值
    var bloggers: any[] = [];
    for (var i: number = 0; i < this.form.bloggers.length; i++) {
      if (this.form.bloggers[i].daily_id && Number(this.form.bloggers[i].daily_id) != 0 && this.form.bloggers[i].blogger_id && Number(this.form.bloggers[i].blogger_id) != 0) {
        bloggers.push({
          daily_id: Number(this.form.bloggers[i].daily_id),
          blogger_id: Number(this.form.bloggers[i].blogger_id),
        });
      }
    }

    //判断返回
    if (this.form.name == "") return this.$message.error("请输入名称");
    if (bloggers.length != 2) return this.$message.error("博主人数 == 2");
    if (!this.form.time[0] || this.form.time[0] == 0) return this.$message.error("清选择开始时间");
    if (!this.form.time[1] || this.form.time[1] == 0) return this.$message.error("清选择结束时间");

    //数据赋值
    this.form.bloggers = bloggers;
    this.form.begin_time = new Date(this.form.time[0] + ":00:00").getTime() / 1000;
    this.form.end_time = new Date(this.form.time[1] + ":00:00").getTime() / 1000;

    //显示等待
    this.dialogLoading = true;

    //添加活动
    await pkRaceSave(this.form);

    //显示提示
    this.$notify({ title: "成功", message: "编辑成功", type: "success", duration: 2000 });

    //隐藏界面
    this.dialogLoading = false;
    this.dialogVisible = false;

    //重新获取数据
    this.getList();
  }

  //删除博主
  private delBlogger(index: number): void {
    //数据赋值
    this.form.bloggers.splice(index, 1);
  }

  //------------------------------ 详情 -----------------------------
  private detail: any = {};
  private detailDialogVisible: boolean = false; //显示随画框
}
